<template>
  <div>
    <v-tabs
      align-with-title
    >
      <v-tabs-slider color="accent"></v-tabs-slider>
      <!-- Todo - update for payroll -->
      <v-tab
        :to="{ name: 'Warrant/Payroll Register - Departments' }"
      >
      Departments
      </v-tab>
      <v-tab
        :to="{ name: 'Warrant/Payroll Register - Accounts' }"
      >
      Accounts
      </v-tab>
      <v-tab
        :to="{ name: 'Warrant/Payroll Register - To Whom' }"
      >
      To Whom
      </v-tab>
    </v-tabs>
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'WarBrowse',
  components: {
  },
};
</script>
